<!-- 中心介绍 -->

<template>
  <div class="box">
    <div class="nav_top">
      <div class="top">
        <top-nav></top-nav>
      </div>
      <div class="text">
        <div class="first">绿水青山就是金山银山</div>
      </div>
      <!-- <h1>环保装备交易平台</h1>
      <h2>服务环境产业上下游</h2> -->
    </div>
    <div style="background-color: #f5f5f5;">
      <div class="content_c">
        <el-tabs :tab-position="tabPosition" v-model="defaultName" @tab-click="handleClick">
          <el-tab-pane :disabled="true">
            <span slot="label"><img style="
    width: 30px;
    height: 6px;" src="../assets/center/left.png" alt=""> 中心介绍 <img style="
    width: 30px;
    height: 6px;" src="../assets/center/right.png" alt=""></span>
          </el-tab-pane>
          <el-tab-pane label="中心概况">


            <div class="crumbs">
              <div class="crumbs_img"><img src="../assets/home.png" alt=""></div>
              <div class="crumbs_text">
                您当前得位置:</div>
              <div class="crumbs_content">
                <span>首页 ></span>
                <span>中心介绍 ></span>
                <span>中心概况</span>
              </div>
            </div>

            <div class="centent_center">
              <div class="part1" v-if="profileList && profileList.length > 3">
                <!-- <div class="title">{{profileList[0].title}}</div> -->
                <!-- <div class="abc">Company profile </div> -->
                <p class="part1" v-html="profileList[3].contentEdit"></p>
              </div>
              <div class="part2" v-if="profileList && profileList.length > 0">
                <div class="img"><img :src="profileList[2].pathUpload" alt=""></div>
                <div class="title">
                  <!-- <div class="title">{{profileList[2].title}}</div> -->
                  <!-- <div class="abc">Company profile </div> -->
                  <p class="mar" v-html="profileList[2].contentEdit"></p>
                </div>
              </div>
              <div class="part3" v-if="profileList && profileList.length > 0">
                <div class="title">
                  <!-- <div class="title">{{profileList[1].title}}</div> -->
                  <!-- <div class="abc">Company profile </div> -->
                  <p class="mar" v-html="profileList[1].contentEdit"></p>
                </div>
                <div class="img" v-if="profileList && profileList.length > 1"><img :src="profileList[1].pathUpload"
                    alt="">
                </div>
              </div>
              <div class="part4">
                <div>
                  <h1 class="title">发展历程</h1>
                  <div class="img"><img src="../assets/development .png" alt=""></div>
                  <!-- <p class="part4" v-html="profileList[0].contentEdit"></p> -->
                </div>

              </div>
            </div>

          </el-tab-pane>
          <el-tab-pane label="组织架构">


            <div class="crumbs">
              <div class="crumbs_img"><img src="../assets/home.png" alt=""></div>
              <div class="crumbs_text">
                您当前得位置:</div>
              <div class="crumbs_content">
                <span>首页 ></span>
                <span>中心介绍 ></span>
                <span>组织架构</span>
              </div>
            </div>
            <!-- 6755b540-d3e4-4833-857b-179beb2aaeb0 -->
            <!-- <router-link :to="`/listDetails?pid=${profileListtwo[1].typeidSelsource}`">
            <img :src="profileListtwo[1].pathUpload" alt="" /></router-link> -->
            <!-- <a href="http://192.168.0.36:8080/listDetails?pid=6755b540-d3e4-4833-857b-179beb2aaeb0">1231</a> -->

            <div class="centent_center">
              <div class="part" v-if="profileListtwo && profileListtwo.length > 0">
                <p class="part_team" v-html="profileListtwo[0].contentEdit"></p>
              </div>

              <!-- <div class="part" style="padding-top: 1.25rem;">
                <div v-if="profileListtwo&&profileListtwo.length>0">
                  <p class="part_perple" v-html="profileListtwo[1].contentEdit"></p>
                </div>
              </div> -->
              <!-- http://www.rcees-csj.com -->
              <div class="part_img">
                <div class="part_title">{{ arrSel[0].title||'' }}</div>
                <div class="part_list" v-if="(arrSel&&arrSel.length>0)">

                  <div class="part_item" v-for="(i, index) in arrSel" :key="index">
                    <router-link :to="`/listDetails?pid=${i.other}`">
                      <img :src="i.pathUpload" alt="" class="img_path" /></router-link>
                  </div>
                </div>
              </div>
            </div>

          </el-tab-pane>
          <el-tab-pane :label="item.typename" v-for="(item, index) in leftList" :key="index" :name="item.typename">
            <div class="crumbs">
              <div class="crumbs_img"><img src="../assets/home.png" alt=""></div>
              <div class="crumbs_text">
                您当前得位置:</div>
              <div class="crumbs_content">
                <span>首页 ></span>
                <span>中心介绍 ></span>
                <span>{{ item.typename }}</span>
              </div>
            </div>
            <div class="blog-detail">
              <div class="text mb-30">
                <!-- <h6 class="detail-title">{{ detailsItem.title }}</h6> -->
                <!-- <p class="time">发布时间：{{ detailsItem.createTime }}</p> -->
                <p class="rich" v-html="detailsItem.contentEdit"></p>
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane label="产业化公司">


            <div class="crumbs">
              <div class="crumbs_img"><img src="../assets/home.png" alt=""></div>
              <div class="crumbs_text">
                您当前得位置:</div>
              <div class="crumbs_content">
                <span>首页 ></span>
                <span>中心介绍 ></span>
                <span>产业化公司</span>
              </div>
            </div>
            <div class="centent_center">

              <div class="part_img">
                <div class="part_title">{{ companyList[0].title||'' }}</div>
                <div class="part_list_c"  v-if="(companyList&&companyList.length>0)">

                  <div class="part_item_c" v-for="(i, index) in companyList" :key="index">
                    <router-link :to="`/listDetails?pid=${i.other}`">
                      <img :src="i.pathUpload" alt="" class="img_path_c" /></router-link>
                  </div>
                </div>
              </div>
            </div>

          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
    <div class="content5">
      <bottom></bottom>
    </div>
  </div>
</template>

<script>
import topNav from "../components/topNav";
import bottom from "../components/bottom";
import { province, menuList, getInfo, listPain } from "../api/api";

export default {
  components: {
    topNav,
    bottom,
  },
  data() {
    return {
      tabPosition: "left",
      newsList: [],
      leftList: [],
      detailsItem: {},
      defaultName: '1',
      profileList: [],
      profileListtwo: [],
      arrSel: [],//现任领导
      companyList: [],//产业化公司
    };
  },
  beforeMount() {
    let _province = localStorage.getItem("province");
    if (!_province) {
      this.getprovince();
    }
  },
  mounted() {
    this.init();

  },
  methods: {
    //获取省市
    async getprovince() {
      let res = await province();
      if (res.ResultCode == 0) {
        // console.log(res, "res");
        localStorage.setItem("province", JSON.stringify(res.Data));
      }
    },
    async init() {
      let res = await menuList();
      console.log(res.Data[1].childs[0].pid, "res");
      if (res.ResultCode == 0) {
        this.getDateOne(res.Data[1].childs[0].pid)
        this.getDateTwo(res.Data[1].childs[1].pid)
        this.leftList = res.Data[1].childs.slice(2,4);
        // console.log(this.leftList, 999);
        this.getDate(this.leftList[0].pid);
        this.getDateFour(res.Data[1].childs[4].pid)
      }
    },
    async details(e) {
      let res = await getInfo({
        pid: e,
      });
      if (res.ResultCode == 0) {
        res.Data.contentEdit = res.Data.contentEdit.replace(/pt/g, 'px')
        // console.log(res.Data, "res详情");
        this.detailsItem = res.Data;
      }
    },
    handleClick(tab) {
      let pidnum = ''
      if (tab.index == 1 || tab.index == 2) {
        return
      }
      pidnum = this.leftList[tab.index - 3].pid
      this.getDate(pidnum);
    },
    async getDate(e) {
      let pids = "";
      let res = await listPain({
        typeidSelsource: e,
        page: 1,
        limit: 10,
      });
      if (res.ResultCode == 0) {
        console.log(res.Data, "res列表");
        if (res.Data.Items && res.Data.Items.length > 0) {

          pids = res.Data.Items[0].pid;
        }
      }
      this.details(pids);
    },
    async getDateOne(e) {
      let res = await listPain({
        typeidSelsource: e,
        page: 1,
        limit: 10,
      });
      if (res.ResultCode == 0) {
        this.profileList = res.Data.Items
      }
    },
    async getDateTwo(e) {
      let res = await listPain({
        typeidSelsource: e,
        page: 1,
        limit: 10,
      });
      if (res.ResultCode == 0) {
        this.profileListtwo = res.Data.Items
        let arr = res.Data.Items.filter(i => i.title == '现任领导')
        this.arrSel = arr
        console.log(arr, '123123');
      }
    },
    async getDateFour(e) {
      let res = await listPain({
        typeidSelsource: e,
        page: 1,
        limit: 10,
      });
      if (res.ResultCode == 0) {
        this.companyList = res.Data.Items
        console.log(this.companyList, '123123');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
// 中心概况新
.el-tab-pane {
  margin-left: 6.25rem;
}

.centent_center {
  width: 100%;
  height: 100%;
  overflow: hidden;
  text-align: left;
  color: #333333;

  .part {
    background-color: #fff;
    margin-bottom: 3.125rem;
    text-align: center;
    padding-bottom: 1.25rem;
  }

  .part1,
  .part2,
  .part3,
  .part4 {
    background-color: #fff;
    margin-bottom: 1.25rem;
    // padding: 30px 20px;
  }

  .part1,
  .part4 {
    .title {
      text-align: center;
      font-weight: 500;
      font-size: 1.875rem;
    }

    .abc {
      text-align: center;
      font-weight: 500;
      font-size: 1.375rem;

    }

  }

  .part1 {
    padding: .625rem 1.875rem;
    line-height: 2;
  }

  .part4 {

    text-align: center;

    .title {
      // margin: 20px auto;
      padding-top: 1.25rem;
      font-weight: bold;
      font-size: 1.875rem;
    }

    .img {
      // width: 70.125rem;
      // height: 20.25rem;
      margin: 1.25rem auto;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }

  .part2 {
    height: 19.5625rem;

    .title {
      text-align: left;
      font-weight: 500;
      font-size: 1.875rem;
      padding: 1.25rem;
      line-height: 1;
      overflow: hidden;
    }

    .img {
      width: 41.25rem;
      height: 100%;

      img {
        width: 100%;
        height: 100%;
      }
    }


  }

  .part3 {

    height: 19.5625rem;

    .title {
      text-align: left;
      font-weight: 500;
      font-size: 1.875rem;
      padding: 1.25rem;
      line-height: 1;
    }

    .img {
      width: 41.25rem;
      height: 100%;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
      }
    }


  }

  .part3,
  .part2 {
    display: flex;
    justify-content: space-between;
    text-align: center;

    &>div {
      flex: 1;
    }
  }
}





.rich {
  ::v-deep img {
    // width:100%;
    margin: auto;
  }

  ::v-deep p {
    line-height: 3;
    text-indent: 0 !important;
  }

  ::v-deep h1 {
    margin: 1.25rem;
    text-indent: 0 !important;
  }

}

// 组织架构的v-html
.part_team {
  ::v-deep h1 {
    text-indent: 0 !important;
  }
}

.part_perple {
  ::v-deep p {
    text-indent: 0 !important;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  ::v-deep a img {
    margin: 0 45px;

  }
}

.mar {
  ::v-deep p {
    margin-bottom: .9375rem;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 7;
  }

}


.box {
  width: 100%;
  box-sizing: border-box;

  .nav_top {
    width: 100%;
    height: 50vh;
    background-image: url("../assets/banner_mao.png");
    background-repeat: no-repeat;
    background-size: cover;
    box-sizing: border-box;

    .text {
      height: calc(100% - 100px);
      display: flex;
      flex-direction: column;
      justify-content: center;
      color: #ffffff;
      text-align: center;
      font-size: 3.125rem;
      font-family: 'YanShiYouRanXiaoKai';


      .first {
        font-size: 6.875rem;
        // font-weight: bold;
        margin-bottom: 3.125rem;
      }
    }

    .top {
      width: 100%;
      height: 6.25rem;
      background-size: 100% 6.25rem;
      background-repeat: no-repeat;
    }

    h1 {
      font-size: 4.125rem;
      font-family: PingFang SC;
      font-weight: bold;
      color: #ffffff;
      text-align: center;
      margin-top: 8.75rem;
    }

    h2 {
      font-size: 2.5rem;
      font-family: PingFang SC;
      font-weight: bold;
      color: rgba($color: #ffffff, $alpha: 0.8);
      text-align: center;
      margin-top: 3.1875rem;
    }
  }

  .content_c {
    width: 87.5rem;
    margin: 0 auto;
    padding-top: 4.5rem;
    padding-bottom: 3.125rem;
    // 新闻列表


    .blog-detail {
      width: 100%;
      height: 100%;
      padding: 0 9.375rem;
      margin: 1.25rem auto;
      overflow: hidden;
      text-align: left;
      background-color: #fff;
    }

    .blog-detail .detail-title {
      text-align: center;
      font-size: 2rem;
      font-weight: bold;
      margin: 1.25rem auto;
    }

    .blog-detail .text {
      width: 100%;
    }

    .blog-detail .text .time {
      text-align: center;
      margin: 1.25rem auto;
    }

    p.rich {
      margin: auto;
    }

    p {
      text-indent: 1.875rem;
    }
  }

  // 面包屑
  .crumbs {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    color: #666666;
    font-size: 14px;
    font-weight: 500;

    height: 50px;

    &_img {
      width: 18px;
      height: 18px;

      img {
        width: 100%;
        height: 100%;
      }
    }

    &_text {
      padding-left: 10px;
    }

    &_content {
      color: #666666;
      font-size: 14px;
    }
  }

  .content5 {
    width: 100%;
    height: 19.375rem;
    background: #f5f5f5;
  }
}


::v-deep {
  .el-tabs__content {
    // background-color: #fff;
  }

  .el-tabs__nav.is-left {
    width: 200px;
    background-color: #fff;
  }

  .el-tabs__item {
    height: 60px;
    line-height: 60px;
  }

  .el-tabs--left .el-tabs__item.is-left {
    text-align: center;
    font-size: 18px;
    // font-family: AlibabaPuHuiTi-2-55-Regular;

    span {
      font-size: 20px;
    }


  }

  .el-tabs__item:hover {
    background-color: rgba(36, 119, 228, .1);
    // font-family: AlibabaPuHuiTi-2-75-SemiBold;
  }

  .el-tabs__item.is-active {
    background-color: rgba(36, 119, 228, .1);
    // font-family: AlibabaPuHuiTi-2-75-SemiBold;

  }

  #tab-0 {
    background-color: #356CEB;
    color: #fff;
    // font-family: AlibabaPuHuiTi-2-75-SemiBold;

  }

  .el-tabs__active-bar.is-right {}

  .part4 {
    img {
      padding: 20px 36px;
    }

  }
}
</style>

<style lang="scss" scoped>
// 现任领导】
.part_img {
  padding: 50px;
  background-color: #ffffff;

  .part_title {
    font-size: 32px;
    font-weight: bolder;
    text-align: center;
    margin-bottom: 50px;
  }
  .part_list_c{
    display: flex;
    justify-content: space-around;
    // flex-direction: row-reverse;
    flex-wrap: wrap;
    align-items: center;
    padding: 0 100px;
  }

  .part_list {

    display: flex;
    justify-content: space-around;
    flex-direction: row-reverse;
    flex-wrap: wrap;
    align-items: center;

    .part_item {
      .img_path {
        width: 159px;
        height: 211px;
      }
    }
  }
}
</style>